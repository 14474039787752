import React, { useEffect, useState } from "react";
import DoughnutChartWithPercentage from "./hollowCircleWithPercentage";
import "./portfolio.css";

interface PortfolioItem {
  identifier: string;
  denominationAsset: string;
  portfolioUSD: number;
}
interface ExampleTable1Props {
  address: string | undefined;
  isConnected: any;
}

const ExampleTable1: React.FC<ExampleTable1Props> = ({
  address,
  isConnected,
}) => {
  const [chainFrequencyMap, setChainFrequencyMap] = useState<
    Map<string, number>
  >(new Map());
  const [totalPortfolioUSD, setTotalPortfolioUSD] = useState<number>(0);
  const [assetPercentageMap, setAssetPercentageMap] = useState<
    Map<string, number>
  >(new Map());
  const [chainPercentageMap, setChainPercentageMap] = useState<
    Map<string, number>
  >(new Map());
  useEffect(() => {
    const fetchData = async () => {
      try {
        const balanceResponse = await fetch(
          `https://api.rivera.money/portfolio/${address}`
        );

        const balanceData: { portfolio: PortfolioItem[] } =
          await balanceResponse.json();
        const extractedData = balanceData.portfolio;
        const assetTotalMap = new Map<string, number>();
        const chainFrequencyMap = new Map<string, number>();

        let totalUSD = 0;
        extractedData.forEach((item) => {
          const { identifier, denominationAsset, portfolioUSD } = item;
          let chain = identifier.split("_")[1];
          if (chain === "5000") {
            chain = "mantle";
          } else if (chain === "169") {
            chain = "manta";
          } else if (chain === "40") {
            chain = "telos";
          } else if (chain === "42161") {
            chain = "Arbitrum";
          } else if (chain === "666666666") {
            chain = "degen";
          } else if (chain === "223") {
            chain = "bsquared";
          } else if (chain === "137") {
            chain = "polygonPoS";
          } else if (chain === "1116") {
            chain = "coredao";
          } else if (chain === "8453") {
            chain = "base";
          }
          if (portfolioUSD) {
            assetTotalMap.set(
              denominationAsset,
              (assetTotalMap.get(denominationAsset) || 0) + portfolioUSD
            );
            chainFrequencyMap.set(
              chain,
              (chainFrequencyMap.get(chain) || 0) + portfolioUSD
            );
            totalUSD += portfolioUSD;
          }
        });

        const assetPercentageMap = new Map<string, number>();
        assetTotalMap.forEach((total, asset) => {
          const percentage = (total / totalUSD) * 100;
          assetPercentageMap.set(asset, Number(percentage.toFixed(2)));
        });

        const chainPercentageMap = new Map<string, number>();
        chainFrequencyMap.forEach((total, chain) => {
          const percentage = (total / totalUSD) * 100;
          chainPercentageMap.set(chain, Number(percentage.toFixed(2)));
        });
        setAssetPercentageMap(assetPercentageMap);
        setChainPercentageMap(chainPercentageMap);
        setTotalPortfolioUSD(totalUSD);
        setChainFrequencyMap(chainFrequencyMap);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [address, isConnected]);
  return (
    <div className="pie-container">
      <div className="Doughnutflex-container">
        <DoughnutChartWithPercentage
          dexFrequencyMap={assetPercentageMap}
          title="Your Assets"
        />
        <DoughnutChartWithPercentage
          dexFrequencyMap={chainPercentageMap}
          title="Chains"
        />
      </div>
    </div>
  );
};

export default ExampleTable1;
