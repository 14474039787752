import React, { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import MyComponent from "./OG";
import clock from "../../assets/images/clock.svg";
import DoughnutChartContainer from "../../component/DoughtnutContainer";
import { Tooltip } from "primereact/tooltip";
import infoCircleImg from "../../assets/images/info_circle.svg";
import { useAccount } from "wagmi";
import ConnectBtn from "../../component/ConnectBtn";
import ExampleTable1 from "./expanTable";
import "./portfolio.css";

interface VaultItem {
  displayName: string;
  identifier: string;
}
interface Transaction {
  identifier: string;
  timestamp: string;
  transactionHash: string;
  chainRPC: string;
  vaultAddress: string;
  denominationAsset: string;
  denominationDecimal: string;
  eventName: string;
  caller: string;
  receiver: string;
  assets: number;
  shares: number;
  symbol: string;
  valueUSD: number;
  displayName: VaultItem;
}
interface portfolio {
  identifier: string;
  timestamp: string;
  denominationAsset: string;
  totalAssets: number;
  totalShares: string;
  portfolioUSD: number;
}
interface ApiResponse {
  transactions: Transaction[];
  portfolio: portfolio[];
}

const circle0 = [{ value: 100, color: "#D3D3D3", label: "Connect wallet" }];
const circleDataEmpty: {
  value: number;
  className?: string | undefined;
  color: string;
  image?: string | undefined;
  label: string;
}[][] = [circle0];
const ExampleTable: React.FC = () => {
  // const address = "0x6C47DCbE1985B717488a2aA6Aeed209618d93c5E";
  const { address, isConnected } = useAccount();
  const [currentPage, setCurrentPage] = useState(1);
  const [apiResponse, setApiResponse] = useState<ApiResponse | null>(null);
  const [selectedDenomination, setSelectedDenomination] = useState<
    string | null
  >(null);
  const [selectedEvent, setSelectedEvent] = useState<string | null>(null);
  const [totalUSD, setTotalUSD] = useState<number>(0);
  const [identifiersWithAssets, setIdentifiersWithAssets] = useState<string[]>(
    []
  );
  const [vaultsWithName, setVaultsWithName] = useState<VaultItem[]>([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://api.rivera.money/portfolio/${address}`
        );
        const data: ApiResponse = await response.json();
        setApiResponse(data);
        let accumulatedTotalUSD = 0;

        if (data && data.portfolio) {
          data.portfolio.forEach((item: portfolio) => {
            if (item.portfolioUSD) {
              accumulatedTotalUSD += item.portfolioUSD;
            }
          });
          const identifiers = data.portfolio
            .filter((item: portfolio) => item.totalAssets > 0)
            .map((item: portfolio) => item.identifier);
          setIdentifiersWithAssets(identifiers);
        }

        // new code
        const secondResponse = await fetch("https://api.rivera.money/vaults");

        const bigData: any = await secondResponse.json();
        const secondData = bigData.map((vault: VaultItem) => {
          return {
            identifier: vault.identifier,
            displayName: vault.displayName,
          };
        });
        setVaultsWithName(secondData);
        setTotalUSD(Number(accumulatedTotalUSD.toFixed(4)));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [address]);

  const transactions: Transaction[] = apiResponse?.transactions || [];
  const vaults: portfolio[] = apiResponse?.portfolio || [];

  const denominationOptions = [
    "All",
    "USDC",
    "WETH",
    "USDT",
    "WTLOS",
    "WDEGEN",
    "DEGEN",
  ];
  const eventOptions = ["All", "Withdraw", "Deposit"];

  const parseDate = (dateString: string): Date => {
    const [datePart, timePart, period] = dateString.split(/[ ,]+/);
    const [day, month, year] = datePart.split("/").map(Number);
    let [hours, minutes, seconds] = timePart.split(":").map(Number);

    if (period.toLowerCase() === "pm" && hours !== 12) {
      hours += 12;
    } else if (period.toLowerCase() === "am" && hours === 12) {
      hours = 0;
    }

    return new Date(year, month - 1, day, hours, minutes, seconds);
  };

  const filteredTransactions = transactions
    .filter(
      (item) =>
        (!selectedDenomination ||
          item.denominationAsset === selectedDenomination ||
          selectedDenomination === "All") &&
        (!selectedEvent ||
          item.eventName === selectedEvent ||
          selectedEvent === "All")
    )
    .sort(
      (a, b) =>
        parseDate(b.timestamp).getTime() - parseDate(a.timestamp).getTime()
    );
  function formatDate(timestamp: string): string {
    const parts = timestamp.split(",")[0].split("/");
    const day = parseInt(parts[0]);
    const month = parseInt(parts[1]);
    const year = parseInt(parts[2]);
    return `${day}/${month}/${year}`;
  }
  const itemsPerPage = 10;
  const totalPages = Math.ceil(filteredTransactions.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentTransactions = filteredTransactions.slice(startIndex, endIndex);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  if (!isConnected) {
    return (
      <div className="custom-container">
        <div className="vault-label">
          <div className="frame-parent2">
            <div className="portfolio-parent">
              <h3 className="portfolio redHatFont fontbold portfolio_font_size ">
                Portfolio
              </h3>
              <div className="all-polygon redHatFont fontbold">
                <ConnectBtn />
              </div>
            </div>
          </div>
          <div className="txtAlgnRight"></div>
        </div>
        <div className="Doughnutflex-container">
          <DoughnutChartContainer
            data={circleDataEmpty}
            showLabel={false}
            showValue={false}
            title="Your Assets"
          />
          <DoughnutChartContainer
            data={circleDataEmpty}
            showLabel={false}
            showValue={false}
            title="Chains"
          />
        </div>
        <div className="frame table-label">
          <h3 className="transaction-history2 redHatFont fontbold">Vaults</h3>
          <div className="flexopt redHatFont">
            <label className="fontbold flexnone redHatFont">
              Strategies
              <Dropdown
                className="bgwhite redHatFont imDropwdth"
                placeholder="Select "
              />
            </label>
            <label className="fontbold flexnone redHatFont">
              Chain:
              <Dropdown
                className="bgwhite redHatFont imDropwdth"
                onChange={(e) => {}}
                placeholder="Select a Day"
              />
            </label>
          </div>
        </div>
        <div className="clock-container hist_empty mx">
          <div className="head-container">
            <h1 className="heading redHatFont fnt_size_600">Vault </h1>
            <h1 className="heading redHatFont fnt_size_600">Strategy</h1>
            <h1 className="heading redHatFont fnt_size_600">Chain</h1>
            <h1 className="heading redHatFont fnt_size_600">Dex</h1>
            <h1 className="heading redHatFont fnt_size_600">Vault Balance </h1>
            <h1 className="heading redHatFont fnt_size_600">Portfolio</h1>
          </div>
          <div className="centered-content">
            <img src={clock} alt="Clock Icon" className="clock-image" />
            <p className="Clocktext redHatFont fnt_size_600">
              Please connect your wallet.
              <br />
              Your vaults distribution will be visible here.
            </p>
          </div>
        </div>
        <div className="frame table-label">
          <h3 className="transaction-history2 redHatFont fontbold">
            Transaction History
          </h3>
          <div className="flexopt redHatFont">
            <label className="fontbold flexnone redHatFont">
              Asset
              <Dropdown
                className="bgwhite redHatFont imDropwdth"
                placeholder="Select denomination"
              />
            </label>
            <label className="fontbold flexnone redHatFont">
              Event
              <Dropdown
                className="bgwhite redHatFont imDropwdth"
                onChange={(e) => {}}
                placeholder="Select an event"
              />
            </label>
          </div>
        </div>
        <div className="clock-container hist_empty mx">
          <div className="head-container">
            <h1 className="heading redHatFont fnt_size_600">Vault </h1>
            <h1 className="heading redHatFont fnt_size_600">All</h1>
            <h1 className="heading redHatFont fnt_size_600">Date</h1>
            <h1 className="heading redHatFont fnt_size_600">Assets</h1>
            <h1 className="heading redHatFont fnt_size_600">Volume</h1>
          </div>

          <div className="centered-content">
            <img src={clock} alt="Clock Icon" className="clock-image" />
            <p className="Clocktext redHatFont fnt_size_600">
              Please connect your wallet.
              <br />
              Your vaults distribution will be visible here.
            </p>
          </div>
        </div>
      </div>
    );
  }
  if (!apiResponse) {
    return (
      <div className="custom-container">
        <div className="vault-label">
          <div className="frame-parent2">
            <div className="portfolio-parent">
              <h3 className="portfolio redHatFont fontbold portfolio_font_size ">
                Portfolio
              </h3>
              <div className="all-polygon redHatFont fontbold">
                <ConnectBtn />
              </div>
            </div>
          </div>
          <div className="txtAlgnRight"></div>
        </div>
        <div className="Doughnutflex-container">
          <DoughnutChartContainer
            data={circleDataEmpty}
            showLabel={false}
            showValue={false}
            title="Your Assets"
          />
          <DoughnutChartContainer
            data={circleDataEmpty}
            showLabel={false}
            showValue={false}
            title="Chains"
          />
        </div>
        <div className="frame table-label">
          <h3 className="transaction-history2 redHatFont fontbold">Vaults</h3>
          <div className="flexopt redHatFont">
            <label className="fontbold flexnone redHatFont">
              Startegies
              <Dropdown
                className="bgwhite redHatFont imDropwdth"
                placeholder="Select a Vault"
              />
            </label>
            <label className="fontbold flexnone redHatFont">
              Chain:
              <Dropdown
                className="bgwhite redHatFont imDropwdth"
                onChange={(e) => {}}
                placeholder="Select a Day"
              />
            </label>
          </div>
        </div>

        <div className="clock-container hist_empty mx">
          <div className="heading-container">
            <h1 className="heading redHatFont fnt_size_600">Vault </h1>
            <h1 className="heading redHatFont fnt_size_600">Strategy</h1>
            <h1 className="heading redHatFont fnt_size_600">Chain</h1>
            <h1 className="heading redHatFont fnt_size_600">Dex</h1>
            <h1 className="heading redHatFont fnt_size_600">Vault Balance </h1>
            <h1 className="heading redHatFont fnt_size_600">Portfolio</h1>
          </div>
          <div className="centered-content">
            <img src={clock} alt="Clock Icon" className="clock-image" />
            <p className="Clocktext redHatFont fnt_size_600">
              No such transaction available.
              <br />
              Maybe some error occurred .
            </p>
          </div>
        </div>
        <div className="frame table-label">
          <h3 className="transaction-history2 redHatFont fontbold">
            Transaction History
          </h3>
          <div className="flexopt redHatFont">
            <label className="fontbold flexnone redHatFont">
              Asset
              <Dropdown
                className="bgwhite redHatFont imDropwdth"
                placeholder="Select denomination"
              />
            </label>
            <label className="fontbold flexnone redHatFont">
              Event
              <Dropdown
                className="bgwhite redHatFont imDropwdth"
                onChange={(e) => {}}
                placeholder="Select an event"
              />
            </label>
          </div>
        </div>
        <div className="clock-container hist_empty mx">
          <div className="heading-container">
            <h1 className="heading redHatFont fnt_size_600">Vault </h1>
            <h1 className="heading redHatFont fnt_size_600">Event</h1>
            <h1 className="heading redHatFont fnt_size_600">Date</h1>
            <h1 className="heading redHatFont fnt_size_600">Assets</h1>
            <h1 className="heading redHatFont fnt_size_600">Volume</h1>
          </div>

          <div className="centered-content">
            <img src={clock} alt="Clock Icon" className="clock-image" />
            <p className="Clocktext redHatFont fnt_size_600">
              No such transaction available.
              <br />
              Maybe some error occurred .
            </p>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="custom-container">
      <div>
        <div className="vault-label bgGrad flex first_section outer_section_detail notMobileDevice">
          <div className="frame-parent2">
            <div className="portfolio-parent">
              <div className="port_flex">
                <h3 className="portfolio redHatFont fontbold portfolio_font_size">
                  Portfolio
                </h3>
              </div>
              <div className="all-polygon redHatFont fontbold">
                <span>${totalUSD}</span>
                {/* <span className="span">12</span> */}
                <img
                  className="toolTipHolding ml-1"
                  src={infoCircleImg}
                  data-pr-tooltip="The displayed portfolio balance has already accounted for the vault performance fee."
                  alt="info"
                />
                <Tooltip
                  target=".toolTipHolding"
                  mouseTrack
                  mouseTrackLeft={10}
                />
              </div>
            </div>
          </div>
          <div className="txtAlgnRight"></div>
        </div>
        <ExampleTable1 address={address} isConnected={isConnected} />
        <div className=" w-1"></div>
        <MyComponent address={address} />
        <div className="frame table-label">
          <h3 className="">Transaction History</h3>
          <div className="flexopt">
            <div className="flexopt redHatFont flexnone">
              <label className="fontbold  redHatFont">Asset</label>
              <Dropdown
                className="bgwhite redHatFont imDropwdth"
                value={selectedDenomination}
                options={denominationOptions.map((option) => ({
                  label: option,
                  value: option,
                }))}
                onChange={(e) => setSelectedDenomination(e.value)}
                placeholder="All"
              />
            </div>
            <div className="flexopt redHatFont flexnone">
              <label className="fontbold flexnone redHatFont">Event</label>
              <Dropdown
                className="filter-dropdown bgwhite redHatFont imDropwdth"
                value={selectedEvent}
                options={eventOptions.map((option) => ({
                  label: option,
                  value: option,
                }))}
                onChange={(e) => setSelectedEvent(e.value)}
                placeholder="All"
              />
            </div>
          </div>
        </div>
        {filteredTransactions.length === 0 ? (
          <div className="clock-container hist_empty mx">
            <div className="heading-container">
              <h1 className="heading redHatFont fnt_size_600">Vault</h1>
              <h1 className="heading redHatFont fnt_size_600">Event</h1>
              <h1 className="heading redHatFont fnt_size_600">Date</h1>
              <h1 className="heading redHatFont fnt_size_600">Assets</h1>
              <h1 className="heading redHatFont fnt_size_600">Volume</h1>
            </div>
            <div className="centered-content">
              <img src={clock} alt="Clock Icon" className="clock-image" />
              <p className="Clocktext redHatFont fnt_size_600">
                No such .<br />
                transaction history will be visible here.
              </p>
            </div>
          </div>
        ) : (
          <div className="table-container-wrapper border2Mob">
            <table className="table-container redHatFont">
              <thead className="redHatFont">
                <tr>
                  <th className="redHatFont whitespace-nowrap fnt_size_600">
                    Vault
                  </th>
                  <th className="redHatFont fnt_size_600">Yield Token</th>
                  <th className="redHatFont fnt_size_600">Event</th>
                  <th className="redHatFont fnt_size_600">Date</th>
                  <th className="redHatFont fnt_size_600">Assets</th>
                  <th className="redHatFont fnt_size_600">Volume</th>
                </tr>
                <tr className="table-separator"></tr>
              </thead>
              <tbody>
                {currentTransactions.map((item, index) => (
                  <tr key={index} className="whitespace-nowrap">
                    <td className="redHatFont fontbold">
                      {vaultsWithName.find(
                        (vault) => vault.identifier === item.identifier
                      )?.displayName || item.symbol}
                    </td>
                    <td className="redHatFont fontbold">{item.symbol}</td>
                    <td
                      className="redHatFont fontbold"
                      style={{
                        color:
                          item.eventName === "Withdraw" ? "red" : "#1fd655",
                      }}
                    >
                      {item.eventName}
                    </td>
                    <td className="redHatFont fontbold">
                      {formatDate(item.timestamp)}
                    </td>
                    <td className="redHatFont fontbold">
                      {item.assets.toFixed(4)}&nbsp;{item.denominationAsset}
                    </td>
                    <td
                      className="redHatFont fontbold"
                      style={{
                        color:
                          item.eventName === "Withdraw" ? "red" : "#1fd655",
                      }}
                    >
                      $&nbsp;{item.valueUSD.toFixed(2)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="pagination">
              {Array.from({ length: totalPages }, (_, i) => (
                <button
                  key={i + 1}
                  onClick={() => handlePageChange(i + 1)}
                  className={`pagination-button ${
                    currentPage === i + 1
                      ? "active mt-05 ml-1 mb-3"
                      : "mx-2 mt-05 ml-1 mb-3"
                  }`}
                >
                  {i + 1}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default ExampleTable;
